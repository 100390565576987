.home__container {
	row-gap: 7rem;
}

.home__content {
	grid-template-columns: 116px repeat(2, 1fr);
	padding-top: 5, 5rem;
	column-gap: 2rem;
	align-items: center;
}

.home__social {
	display: grid;
	grid-template-columns: max-content;
	row-gap: 1rem;
}

.home__social-icon {
	font-size: 1.25rem;
	color: var(--title-color);
}

.home__social-icon:hover {
	color: var(--title-color-dark);
}

.home__title {
	font-size: var(--big-font-size);
	margin-bottom: var(--mb-0-25);
}

.home__hand {
	width: 38px;
	height: 38px;
	margin-left: 0.4rem;
}

.home__subtitle {
	position: relative;
	font-size: var(--h3-font-size);
	padding-left: 5.4rem;
	font-weight: var(--font-normal);
	margin-bottom: var(--mb-1);
}


.home__subtitle::before {
	content: '';
	position: absolute;
	width: 70px;
	height: 1px;
	background-color: var(--text-color);
	top: 1rem;
	left: 0;
}

.home__description {
	max-width: 400px;
	margin-bottom: var(--mb-3);
}

.home__img {
	background: url(../../assets/profile.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 200px;
	height: 300px;
	border-radius: 10px;
	box-shadow: inset 0 0 0 2px rgb(255 255 255 /30%);
	order: 1;
	justify-self: center;
	animation: profile__animate 6s ease-in-out infinite 1s;
}

@keyframes profile__animate {
	0% {
		border-radius: 10% 15% 10% 15%/20% 10% 15% 10%;
	}

	50% {
		border-radius: 30% 30% 30% 30%/50% 60% 50% 40%;
	}

	100% {
		border-radius: 10% 15% 10% 15%/20% 10% 15% 10%;
	}
}


.home__scroll {
	margin-left: 9.25rem;
}

.wheel {
	animation: scroll 2s ease infinite;
}

@keyframes scroll {
	0% {
		transform: translateY(0);
	}

	30% {
		transform: translateY(3.75rem);
	}
}

.home__scroll-name {
	color: var(--title-color);
	font-weight: var(--font-medium);
	margin-right: var(--mb-0-25);
	margin-left: 2px;
}

.home__scroll-arrow {
	font-size: 1.25rem;
	color: var(--title-color);
}

/* Large */
@media screen and (max-width: 992px) {
	.home__content {
		grid-template-columns: 100px repeat(2, 1fr);
		column-gap: 1.25rem;
	}

	.home__hand {
		width: 26px;
		height: 26px;
	}

	.home__subtitle {
		padding-left: 3.75rem;
		margin-bottom: var(--mb-1);
	}

	.home__subtitle::before {
		width: 42px;
		top: 0.8rem;
	}

	.home__description {
		max-width: initial;
		margin-bottom: var(--mb-2-5);
	}

	.home__img {
		width: 250px;
		height: 300px;
		box-shadow: inset 0 0 0 1px rgb(255 255 255 /30%);
	}

	.home__scroll {
		margin-left: 7.5rem;
	}
}

@media screen and (max-width: 768px) {
	.home__content {
		grid-template-columns: .5fr 3fr;
		padding-top: 3.5rem;
	}

	.home__img {
		order: initial;
		justify-self: initial;
	}

	.home__data {
		grid-column: 1/3;
		text-align: justify;
	}

	.home__img {
		box-shadow: inset 0 0 0 4px rgb(255 255 255 /30%);
		width: 200px;
		height: 250px;
	}

	.home__scroll {
		display: none;
	}
}

/* small */

@media screen and (max-width: 350px) {
	.home__img {
		width: 150px;
		height: 200px;
	}

	.home__hand {
		width: 22px;
		height: 22px;
	}
}
